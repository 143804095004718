<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : ''"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : ''"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="商户名称"
              :no-data-text="mchDropData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in mchDropData"
                :key="item.mchId"
                :label="item.mchName + ' | ' + item.mchId"
                :value="item.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>申报类型</i>
            <el-select
              v-model="query.functionCode"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="单向海关申报" value="CUS"></el-option>
              <el-option label="单向国检申报" value="CIQ"></el-option>
              <el-option label="海关和国检同时申报" value="BOTH"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>报关类型</i>
            <el-select
              v-model="query.orderType"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="新增" value="0"></el-option>
              <el-option label="修改" value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>申报状态</i>
            <el-select
              v-model="query.status"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="申报成功" value="100"></el-option>
              <el-option label="申报失败" value="101"></el-option>
              <el-option label="申报中" value="104"></el-option>
            </el-select>
          </div>
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              placeholder="创建时间"
              style="width: 130px;x"
            >
              <el-option label="创建时间" value="CREATE"></el-option>
              <el-option label="申报时间" value="TRADE"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="lineP">
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
            <el-button type="primary" class="outputBt" @click="outputShow" v-auth="'TRADE:CUSTOMS:ORDERS/EXPORT'"
              >导出</el-button
            >
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box" style="margin-right: 16px">
          <p><i>订单总数（笔）</i></p>
          <p>
            <b>{{ tradeNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>订单金额（元）</i></p>
          <p>
            <b>{{ tradeAmount | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>申报时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>商户子订单号</p>
            </td>
            <td>
              <p>三方订单号</p>
              <p>三方子订单号</p>
            </td>
            <td>
              <p>订单金额(元)</p>
            </td>
            <td>
              <p>申报类型</p>
            </td>
            <td>
              <p>海关名称</p>
            </td>
            <td>
              <p>报关类型</p>
            </td>
            <td>
              <p>申报状态</p>
            </td>
            <td style="width: 80px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mainOrderNo }}</p>
              <p>{{ v.subOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.thirdOrderNo }}</p>
              <p>{{ v.thirdTrxNo }}</p>
            </td>
            <td>
              <p>{{ v.amount | formatMoney }}</p>
            </td>
            <td>
              <p>{{ functionCode[v.functionCode] }}</p>
            </td>
            <td>
              <p>
                {{
                  customsCode[v.customsCode]
                    ? customsCode[v.customsCode]
                    : v.customsCode
                }}
              </p>
            </td>
            <td>
              <p>{{ orderType[v.orderType] }}</p>
            </td>
            <td>
              <p>{{ status[v.status] }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <a
                  v-auth="'TRADE:CUSTOMS:ORDERS/SYNC'"
                  href="javascript:;"
                  class="lianjie"
                  @click="getSync(v)"
                  >同步</a
                >
                <a
                  v-auth="'TRADE:CUSTOMS:ORDERS/DETAIL'"
                  href="javascript:;"
                  class="lianjie"
                  @click="tabShow(v)"
                  >详情</a
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <!-- <i>商户订单号</i> -->
            <el-select
              v-model="formData.orderNoType"
              placeholder=""
              style="width: 170px; margin-right: 20px"
            >
              <el-option value="mainOrderNo" label="商户订单号"></el-option>
              <el-option value="subOrderNo" label="商户子订单号"></el-option>
              <el-option value="thirdOrderNo" label="三方订单号"></el-option>
              <el-option value="thirdTrxNo" label="三方子订单号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              maxlength="128"
              style="width: calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
              <el-button class="searchBt" @click="getDetail">查询</el-button>
          </div>        
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>申报时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>商户子订单号</p>
            </td>
            <td>
              <p>三方订单号</p>
              <p>三方子订单号</p>
            </td>
            <td>
              <p>订单金额(元)</p>
            </td>
            <td>
              <p>申报类型</p>
            </td>
            <td>
              <p>海关名称</p>
            </td>
            <td>
              <p>报关类型</p>
            </td>
            <td>
              <p>申报状态</p>
            </td>
            <td style="width: 80px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mainOrderNo }}</p>
              <p>{{ v.subOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.thirdOrderNo }}</p>
              <p>{{ v.thirdTrxNo }}</p>
            </td>
            <td>
              <p>{{ v.amount | formatMoney }}</p>
            </td>
            <td>
              <p>{{ functionCode[v.functionCode] }}</p>
            </td>
            <td>
              <p>
                {{
                  customsCode[v.customsCode]
                    ? customsCode[v.customsCode]
                    : v.customsCode
                }}
              </p>
            </td>
            <td>
              <p>{{ orderType[v.orderType] }}</p>
            </td>
            <td>
              <p>{{ status[v.status] }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <a
                  v-auth="'TRADE:CUSTOMS:ORDERS/SYNC'"
                  href="javascript:;"
                  class="lianjie"
                  @click="getSync(v)"
                  >同步</a
                >
                <a href="javascript:;" class="lianjie" @click="tabShow(v)"
                  >详情</a
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-dialog title="导出交易记录" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.tradeStartDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i> 交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <el-option label="今天" value="1"></el-option>
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.tradeEndDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getCustomsorders,
  getCustomsordersSearch,
  getCustomsordersSync,
  exportCustomsorders,
} from "@/api/trading/cuetoms.js";
import { merchantsDrop , merchantsDropTwo } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
import { URL_API } from "@/utils/apiUrl.js";
export default {
  data() {
    let that = this;
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        functionCode: "",
        orderType: "",
        status: "",
        keyword: "",
      },
      tabData: [],
      totalNum: 0,
      tradeNum: 0,
      tradeAmount: 0,
      functionCode: {
        CUS: "单向海关申报",
        CIQ: "单向国检申报",
        BOTH: "海关和国检同时申报",
      },
      orderType: {
        0: "新增",
        1: "修改",
      },
      customsCode: {
        5141: "广州机场",
        5145: "广州邮办",
        5208: "埔开发区(黄埔)",
        52081: "黄埔状元谷智检口岸",
        5190: "韶关海关",
        8012: "重庆保税",
        5300: "深圳海关",
        5700: "珠海海关(拱北)",
        5100: "广州海关",
        5165: "南沙海关",
        900: "大连海关",
        1000: "海关总署",
        1531: "长春海关",
        3100: "宁波海关",
        2900: "杭州海关",
      },
      status: {
        100: "申报成功",
        101: "申报失败",
        104: "申报处理中",
      },
      formData: {
        orderNoType: "mainOrderNo",
        orderNo: "",
      },
      orderNoType: {
        mainOrderNo: "商户订单号",
        subOrderNo: "商户子订单号",
        thirdOrderNo: "三方订单号",
        thirdTrxNo: "三方子订单号",
      },
      crumbs: "批量查询",
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      mchDrop: [],
      selectTimeType: "",
      mchDropData:[], //商户名称
      keywordData:{
        mchId:"",
        mchName:"",
      },
    };
  },
  computed: {
    ...mapState({
      customsDetail: "trading_customs",
    }),
  },
  created() {
    if (this.customsDetail) {
      this.query = this.customsDetail.query;
      this.formData = this.customsDetail.formData;
      this.keywordData = this.customsDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.mchId != ""){
      this.query.keyword = this.keywordData.mchName + ' | ' + this.keywordData.mchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      setCustoms: "trading_setCustoms",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res.resultStatus) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        // this.query.keyword = this.query.keyword.trim();
      }
      let data = JSON.parse(JSON.stringify(this.query));
      if(this.keywordData != undefined && this.keywordData.mchId != ""){
        data.keyword = this.keywordData.mchId;
      }
      getCustomsorders(data).then((res) => {
        if (res.resultStatus) {
          if (this.crumbs == "批量查询") {
            this.tabData = res.resultData.orders;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNum = res.resultData.totalNum;
            this.tradeNum = res.resultData.tradeNum;
            this.tradeAmount = res.resultData.tradeAmount;
          }
        }
      });
    },
    // 获取详情
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
      };
      if (params.orderNo) {
        getCustomsordersSearch(params).then((res) => {
          if (res.resultStatus) {
            if (this.crumbs != "批量查询") {
              this.tabData = res.resultData;
              if (this.tabData.length == 0) {
                this.$message({
                  message: "暂无数据",
                  duration: 1500,
                });
              }
            }
          }
        });
      } else {
        this.$message.error("请输入"+this.orderNoType[this.formData.orderNoType]);
      }
    },
    // 同步
    getSync(value) {
      getCustomsordersSync(value.customsOrderId).then((res) => {
        if (res.resultStatus) {
          this.$message.success("同步请求成功");
          value = res.resultData;
          return;
        }
      });
    },
    // 详情
    tabShow(value) {
      this.$router.push({
        name: "customs_detail",
        query: {
          id: value.customsOrderId,
        },
      });
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo) {
          this.getDetail();
        }
      }
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        tradeStartDate: "",
        tradeEndDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.tradeStartDate = formatDate(
            +new Date(),
            "yyyy-MM-dd"
          );
          this.outputForm.tradeEndDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.tradeStartDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.tradeEndDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.tradeStartDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.tradeEndDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.tradeStartDate = "";
          this.outputForm.tradeEndDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.tradeStartDate || !this.outputForm.tradeEndDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      exportCustomsorders(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "海关推单查询.csv");
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },
    // 获取商户下拉列表
    _searchAllMch(value) {
      this.mchDropData = [];
      let params = {
        keyword: value,
      };
      merchantsDropTwo(params).then((res) => {
        if (res.resultStatus) {
          this.mchDropData = res.resultData;
          console.log(this.mchDropData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.mchDropData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let mchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.mchDropData.filter(e=>e.mchId === mchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },   
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
      keywordData: this.keywordData,
    };
    this.setCustoms(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 63.5%;
}
.con_from .lineP .item i {
  width: 84px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 84px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 84px - 44.67px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 84px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
.jiaoyi_box {
  width: 49%;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
